import type { FunctionComponent } from 'react';
import type { TypeCartIconProps } from './types';

/**
 * CartIcon
 */
export const CartIcon: FunctionComponent<TypeCartIconProps> = ({
  className = '',
  state = 'DEFAULT',
  colorScheme = {
    ACTIVE: 'var(--tw-color-secondary-500)',
    DEFAULT: 'currentColor',
    DISABLED: '#26262633',
    ERROR: 'var(--tw-color-status-error)',
    LOADING: 'var(--tw-color-secondary-500)',
  },
  ...props
}: TypeCartIconProps) => (
  <svg
    fill="none"
    viewBox="0 0 30 30"
    style={{ color: colorScheme[state] }}
    data-state={state}
    className={`group ${className}`}
    {...props}
  >
    <use
      href="#animate-cart-icon"
      className="hidden group-data-[state=DEFAULT]:inline group-data-[state=DISABLED]:inline"
    />
    <use
      href="#animate-cart-icon-with-hole"
      className="hidden group-data-[state=ACTIVE]:inline group-data-[state=ERROR]:inline group-data-[state=LOADING]:inline"
    />
    <use
      href="#animate-cart-active"
      className="hidden group-data-[state=ACTIVE]:inline"
    />
    <use
      href="#animate-cart-loading"
      className="hidden group-data-[state=LOADING]:inline"
    />
    <use
      href="#animate-cart-error"
      className="hidden group-data-[state=ERROR]:inline"
    />
  </svg>
);

CartIcon.displayName = 'CartIcon';
