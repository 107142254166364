import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';

import { routes } from '@/lib/routes';
import { isOperator } from '@/lib/utils';

import type { FunctionComponent, PropsWithChildren } from 'react';

interface CardProductSeller
  extends PropsWithChildren<{
    slug?: string;
  }> {}

export const Seller: FunctionComponent<CardProductSeller> = ({
  children,
  slug,
}: CardProductSeller): JSX.Element | null => {
  const Comp: React.ElementType = slug ? 'a' : 'span';

  return typeof children === 'string' && isOperator(children) ? null : (
    <ConditionalWrapper
      condition={Boolean(slug)}
      wrapper={(c) => (
        <NextLink href={routes.sellers.seller(slug ?? 'bigcrafters')} passHref>
          {c}
        </NextLink>
      )}
    >
      <Comp className="u-body u-body--xs truncate text-primary-900">
        Vendido por {children}
      </Comp>
    </ConditionalWrapper>
  );
};
