import { NextLink } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeLinkProps } from './types';

export const Category: FunctionComponent<TypeLinkProps> = ({
  href,
  children,
}: TypeLinkProps): JSX.Element =>
  href ? (
    <NextLink href={href}>
      <a className="u-body u-body--xs line-clamp-1 text-secondary-500">
        {children}
      </a>
    </NextLink>
  ) : (
    <span className="u-body u-body--xs line-clamp-1 text-secondary-500">
      {children}
    </span>
  );
