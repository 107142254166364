import type { FunctionComponent } from 'react';
import type { TypePriceProps } from './types';

export const PriceRegular: FunctionComponent<TypePriceProps> = ({
  disabled = false,
  children,
}: TypePriceProps): JSX.Element => (
  <del
    className={`u-body u-body--xs line-clamp-1 flex items-center ${disabled ? 'opacity-20' : 'text-typo-tertiary'} `}
  >
    {children}
  </del>
);
