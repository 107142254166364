import type { TypeColorScheme } from '@/molecules/cart-icon';

export const whiteColorScheme: TypeColorScheme = {
  ACTIVE: 'white',
  DEFAULT: 'white',
  DISABLED: 'white',
  ERROR: 'white',
  LOADING: 'white',
};

export const currentColorColorScheme: TypeColorScheme = {
  ACTIVE: 'currentColor',
  DEFAULT: 'currentColor',
  DISABLED: 'currentColor',
  ERROR: 'currentColor',
  LOADING: 'currentColor',
};
