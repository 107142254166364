import { NextLink } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypePriceProps } from './types';

export const PriceDiscounted: FunctionComponent<TypePriceProps> = ({
  disabled = false,
  children,
  onSale = false,
  href,
}: TypePriceProps): JSX.Element | null => {
  if (!children) {
    return null;
  }

  return (
    <NextLink href={href || '#'}>
      <a
        className={`u-body u-body--m line-clamp-1 font-bold text-primary-900 ${disabled ? 'opacity-20' : onSale ? 'text-secondary-500' : ''}`}
      >
        {children}
      </a>
    </NextLink>
  );
};
