import { forwardRef } from 'react';

import '@/lib/utils/forwardRef';

import { currentColorColorScheme } from '@/lib/utils';

import type { PolymorphicRef } from '@/types';
import type { ElementType } from 'react';
import type { TypeCartButtonProps } from './types';

const VARIANTS = {
  default:
    '-mb-1 max-w-max lg:mb-0 lg:border lg:border-solid lg:border-primary-900 lg:px-3 lg:py-2',
  button:
    'mb-0 border border-solid border-primary-900 px-3 py-2 hover:border-secondary-500',
};

/**
 * Cart Button
 */
const CartButtonBase = <T extends ElementType = 'button'>(
  {
    as,
    state,
    Icon,
    disabled,
    children,
    variant = 'default',
    className = '',
    testid,
    ...props
  }: TypeCartButtonProps<T>,
  ref: PolymorphicRef<T>,
) => {
  const HTMLTag = as || 'button';

  return (
    <HTMLTag
      ref={ref}
      {...(testid && { 'data-playwright': `btn_${testid}` })}
      // TODO: cuando los botones usen tailwind importar el objeto de clases directamente
      className={`u-actions u-actions--chips flex h-7 min-h-[30px] min-w-[30px] cursor-pointer items-center justify-center gap-x-1 rounded-[50px] text-center text-primary-900 transition-colors hover:text-secondary-500 disabled:pointer-events-none disabled:opacity-20 lg:hover:border-primary-600 lg:hover:text-primary-600 ${VARIANTS[variant]} ${className}`}
      disabled={disabled || state === 'LOADING'}
      aria-disabled={disabled || state === 'LOADING'}
      aria-label="Añadir al carrito"
      {...props}
    >
      {children}
      <Icon
        width={18}
        height={18}
        className="h-6 w-6 flex-shrink-0 lg:h-[18px] lg:w-[18px]"
        {...(state && {
          state,
          colorScheme: currentColorColorScheme,
        })}
      />
    </HTMLTag>
  );
};

export const CartButton = forwardRef(CartButtonBase);
