import { Badge } from '@/atoms';

import { CardProduct } from './CardProduct';
import * as childs from './childs';

export type TypeCardProduct = typeof CardProduct & {
  Image: typeof childs.ImageCustom;
  Badge: typeof Badge;
  Category: typeof childs.Category;
  Name: typeof childs.Name;
  Seller: typeof childs.Seller;
  PriceRegular: typeof childs.PriceRegular;
  PriceDiscounted: typeof childs.PriceDiscounted;
};

(CardProduct as TypeCardProduct).Image = childs.ImageCustom;
(CardProduct as TypeCardProduct).Badge = Badge;
(CardProduct as TypeCardProduct).Category = childs.Category;
(CardProduct as TypeCardProduct).Name = childs.Name;
(CardProduct as TypeCardProduct).Seller = childs.Seller;
(CardProduct as TypeCardProduct).PriceRegular = childs.PriceRegular;
(CardProduct as TypeCardProduct).PriceDiscounted = childs.PriceDiscounted;

(CardProduct as TypeCardProduct).Image.displayName = 'CardProduct.Image';
(CardProduct as TypeCardProduct).Badge.displayName = 'CardProduct.Badge';
(CardProduct as TypeCardProduct).Category.displayName = 'CardProduct.Category';
(CardProduct as TypeCardProduct).Name.displayName = 'CardProduct.Name';
(CardProduct as TypeCardProduct).Seller.displayName = 'CardProduct.Seller';
(CardProduct as TypeCardProduct).PriceRegular.displayName =
  'CardProduct.PriceRegular';
(CardProduct as TypeCardProduct).PriceDiscounted.displayName =
  'CardProduct.PriceDiscounted';

export type { TypeCardProductProps } from './types';
export type {
  TypeImageProps,
  TypeLinkProps,
  TypeChildren,
  TypePriceProps,
} from './childs/types';

export default CardProduct as TypeCardProduct;
