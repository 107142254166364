import {
  calculateDiscount,
  gtmPush,
  priceToNumber,
  toCurrency,
} from '@/lib/utils';

import type { Woosb } from '@/lib/graphql/transformers/product';
import type { ItemArray } from '@/types';
import type { TypeBundle } from './types';

export const numProducts = (products: Woosb['products']): number =>
  products.reduce((acc, curr) => acc + curr.quantity, 0);
export const getSelectedProducts = (
  products: Woosb['products'],
): Woosb['products'] => products.filter(({ quantity }) => quantity > 0);
export const woobsIds = (
  selectedProducts: TypeBundle['selectedProducts'],
): string =>
  selectedProducts.map(({ id, quantity }) => `${id}/${quantity}`).join(',');
export const getTotalPrice = (
  products: Woosb['products'],
  type: 'price' | 'regularPrice',
  discount?: Woosb['discount'],
): string => {
  const price = products.reduce(
    (acc, curr) => acc + priceToNumber(curr[type]) * curr.quantity,
    0,
  );

  return toCurrency(calculateDiscount(price, discount));
};

export const initialState = ({
  products,
  discount,
  discountByVariant,
  variants,
  ...others
}: Woosb): TypeBundle => {
  const selectedProducts = getSelectedProducts(products);

  return {
    ...others,
    discountByVariant: structuredClone(discountByVariant),
    discount: { ...discount },
    products: new Map(
      products.map(({ id, ...props }) => [id, { id, ...props }]),
    ),
    selectedProducts,
    initialProducts: products,
    numProducts: numProducts(selectedProducts),
    woobsIds: woobsIds(selectedProducts),
    // Por reglas de negocio el precio se calcula siempre del regularPrice
    // si es un bundle de cajas
    totalPrice: getTotalPrice(
      selectedProducts,
      discountByVariant && discountByVariant?.length > 0
        ? 'regularPrice'
        : 'price',
      discount,
    ),
    totalRegularPrice: getTotalPrice(selectedProducts, 'regularPrice'),
    variants,
    selectedVariant: variants[0]!,
    loading: true,
  };
};

export const getNewState = (
  bundle: TypeBundle,
  productID: number,
  quantity: (actualQuantity: number) => number,
): TypeBundle => {
  const product = bundle.products.get(productID);
  const actualQuantity = product?.quantity ?? 0;
  const newQuantity = quantity(actualQuantity);
  const difference = newQuantity - actualQuantity;
  const products = new Map([
    ...bundle.products,
    [
      productID,
      {
        ...product,
        quantity: newQuantity,
      } as ItemArray<TypeBundle['initialProducts']>,
    ],
  ]);
  const selectedProducts = getSelectedProducts([...products.values()]);
  // Por reglas de negocio el precio se calcula siempre del regularPrice
  // si es un bundle de cajas
  const totalPrice = getTotalPrice(
    selectedProducts,
    bundle.discountByVariant && bundle.discountByVariant?.length > 0
      ? 'regularPrice'
      : 'price',
    bundle.discount,
  );
  const totalRegularPrice = getTotalPrice(selectedProducts, 'regularPrice');

  gtmPush({
    event: 'custom_box',
    action:
      newQuantity > actualQuantity
        ? 'add_to_custom_box'
        : 'remove_from_custom_box',
    ecommerce: {
      items: [
        {
          item_id: productID,
          item_name: product?.name,
          item_category: product?.primaryCategory.name,
          item_brand: product?.brand,
          quantity: Math.abs(difference),
          price: priceToNumber(product?.regularPrice ?? '0'),
        },
      ],
    },
  });

  return {
    ...bundle,
    products,
    selectedProducts,
    numProducts: numProducts(selectedProducts),
    woobsIds: woobsIds(selectedProducts),
    totalPrice,
    totalRegularPrice,
    loading: false,
  };
};

const replacer = (_: string, value: any) =>
  value instanceof Map
    ? {
        dataType: 'Map',
        value: Array.from(value.entries()),
      }
    : value;

const reviver = (_: string, value: any) =>
  typeof value === 'object' && value !== null && value.dataType === 'Map'
    ? new Map(value.value)
    : value;

const BUNDLE_SESSION_STORAGE_KEY = 'currentBundle';

export const saveCurrentBundle = (bundle: TypeBundle) => {
  if (typeof window !== 'undefined')
    sessionStorage.setItem(
      BUNDLE_SESSION_STORAGE_KEY,
      JSON.stringify(bundle, replacer),
    );
};

export const getCurrentBundle = () => {
  const currentBundle =
    typeof window !== 'undefined'
      ? sessionStorage.getItem(BUNDLE_SESSION_STORAGE_KEY)
      : null;

  if (currentBundle) {
    return JSON.parse(currentBundle, reviver) as TypeBundle;
  }

  return null;
};

export const clearCurrentBundle = () => {
  if (typeof window !== 'undefined')
    sessionStorage.removeItem(BUNDLE_SESSION_STORAGE_KEY);
};
