import { oneLine } from 'common-tags';

import type { FunctionComponent } from 'react';
import type { TypeBadgeProps } from './types';

const fontStyle = {
  orange: 'text-secondary-500',
  purple: 'text-tertiary-500',
  red: 'text-status-error',
  black: 'text-primary-900',
};

const backgroundStyle = {
  orange: 'bg-secondary-500',
  purple: 'bg-tertiary-500',
  red: 'bg-status-error',
  black: 'bg-primary-900',
};

const borderStyle = {
  orange: 'border-secondary-500',
  purple: 'border-tertiary-500',
  red: 'border-status-error',
  black: 'border-primary-900',
};

/**
 * Badge
 */
export const Badge: FunctionComponent<TypeBadgeProps> = ({
  className = '',
  style = 'orange',
  outline = false,
  fullRounded,
  roundedTopLeft,
  roundedTopRight,
  roundedBottomLeft,
  roundedBottomRight,
  children,
}: TypeBadgeProps) => (
  <span
    className={oneLine`u-body u-body--s inline-block min-w-12 max-w-max px-1 py-0.5 text-center ${
      outline
        ? `border bg-transparent ${fontStyle[style]} ${borderStyle[style]}`
        : `${backgroundStyle[style]} text-typo-alternative`
    } ${fullRounded ? 'rounded-2xl' : ''} ${roundedTopLeft ? 'rounded-tl-2xl' : ''} ${roundedTopRight ? 'rounded-tr-2xl' : ''} ${roundedBottomLeft ? 'rounded-bl-2xl' : ''} ${roundedBottomRight ? 'rounded-br-2xl' : ''} ${className} `}
  >
    {children}
  </span>
);

Badge.displayName = 'Badge';
