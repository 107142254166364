import type { FunctionComponent } from 'react';
import type { TypeChildren } from './types';

export const Name: FunctionComponent<TypeChildren> = ({
  children,
}: TypeChildren): JSX.Element => (
  <>
    {typeof children === 'string' ? children.replace('&amp;', '&') : children}
  </>
);
