import Image from 'next/future/image';

import { getUrlExtension, isPNG } from '@/lib/utils';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import type { FunctionComponent } from 'react';
import type { TypeImageProps } from './types';

export const ImageCustom: FunctionComponent<TypeImageProps> = ({
  src,
  alt,
  size = 'medium',
}: TypeImageProps): JSX.Element => {
  const srcWithFallback = src && src !== '' ? src : FallBackIMG.src;
  const altWithFallback = alt && alt !== '' ? alt : 'fallback image';
  const objectFit = isPNG(getUrlExtension(srcWithFallback))
    ? 'object-contain'
    : 'object-cover';

  return (
    <Image
      src={srcWithFallback}
      alt={altWithFallback}
      className={`object-center ${objectFit}`}
      draggable="false"
      fill
      {...(size === 'small'
        ? {
            sizes: '166px',
          }
        : size === 'medium'
          ? {
              sizes:
                '(max-width: 360px) 350px, (max-width: 450px) 250px, (max-width: 600px) 300px, 250px',
            }
          : {
              sizes:
                '(max-width: 360px) 350px, (max-width: 450px) 450px, (max-width: 600px) 300px, (max-width: 800px) 370px',
            })}
    />
  );
};
