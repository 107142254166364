import type { FunctionComponent } from 'react';
import type { TypeBoxProps, TypeSize } from './types';

const isActive = (size: TypeSize, activeSize: TypeSize) =>
  size === activeSize ? '#F68217' : '#A3988F';

/**
 * Box
 */
export const Box: FunctionComponent<TypeBoxProps> = ({
  className,
  amount,
  type = 'bottle',
  size = 'small',
}: TypeBoxProps) => (
  <svg
    {...(className && { className })}
    width="64"
    height="58"
    viewBox="0 0 64 58"
    fill="none"
  >
    <g>
      <path d="M8 0H56L64 8H0L8 0Z" fill="#D8D3CE" />

      <path
        d="M0 8H64V55.6312C64 56.7357 63.1046 57.6312 62 57.6312H2C0.895431 57.6312 0 56.7357 0 55.6312V8Z"
        fill="#ECE7E1"
      />

      <rect
        width="32"
        height="6"
        rx="3"
        transform="matrix(0.999994 0.0033851 -0.00272259 0.999996 16 12)"
        fill="#D8D3CE"
      />

      <text
        fill="#76716E"
        xmlSpace="preserve"
        style={{ whiteSpace: 'pre', textTransform: 'lowercase' }}
        fontFamily="Red Hat Display"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0px"
        x="37.156"
        y="53.59"
      >
        {amount}
      </text>

      {type === 'bottle' && (
        <g>
          <path
            d="M8 53.631v-29h-.9a.6.6 0 0 0-.6.6v1.4l-.772 7.361a3 3 0 0 1-.389 1.194L4.5 36.631l-.078.155A4 4 0 0 0 4 38.576v13.348a1.707 1.707 0 0 0 1.707 1.707H8ZM8 53.631v-29h.9a.6.6 0 0 1 .6.6v1.4l.772 7.361a3 3 0 0 0 .389 1.194l.839 1.445.078.155a4 4 0 0 1 .422 1.79v13.348a1.707 1.707 0 0 1-1.707 1.707H8Z"
            fill={isActive(size, 'large')}
          />

          <path
            d="M17.5 53.631v-24.63h-.788a.518.518 0 0 0-.524.51v1.19l-.676 6.251a2.495 2.495 0 0 1-.34 1.014l-.735 1.227-.067.132c-.243.472-.37.992-.37 1.52V52.18c0 .385.157.754.438 1.026.28.271.66.424 1.056.424H17.5ZM17.5 53.631v-24.63h.788c.29 0 .524.229.524.51v1.19l.676 6.251c.038.358.154.703.34 1.014l.735 1.227.068.132c.242.472.369.992.369 1.52V52.18c0 .385-.157.754-.438 1.026-.28.271-.66.424-1.056.424H17.5Z"
            fill={isActive(size, 'medium')}
          />

          <path
            d="M26 53.631v-19.63h-.675c-.248 0-.45.183-.45.407v.947l-.579 4.983c-.033.285-.132.56-.292.808l-.629.978-.058.105c-.209.376-.317.79-.317 1.211v9.036c0 .306.135.6.375.817.24.216.566.338.905.338H26ZM26 53.631v-19.63h.675c.248 0 .45.183.45.407v.947l.579 4.983c.033.285.132.56.292.808l.629.978.058.105c.209.376.317.79.317 1.211v9.036c0 .306-.135.6-.375.817-.24.216-.566.338-.905.338H26Z"
            fill={isActive(size, 'small')}
          />
        </g>
      )}

      {type === 'can' && (
        <g>
          <path
            d="M4.70364 30.3753C4.77594 30.1243 4.82297 29.8911 4.85293 29.9592L4.83703 29.9593C4.80465 29.9594 4.77805 29.9216 4.77792 29.8753L4.77746 29.7129C4.77733 29.6667 4.80371 29.6287 4.8361 29.6286L5.01096 29.628L12.0473 29.6051L12.2033 29.6046C12.2357 29.6045 12.2623 29.6423 12.2624 29.6886L12.2629 29.8518C12.263 29.8981 12.2366 29.9361 12.2043 29.9362L12.1878 29.9362C12.218 29.9209 12.2612 30.2127 12.3023 30.3514C12.5525 31.1996 13.0654 31.4344 13.0063 33.8427L13.0602 51.0918C13.0262 51.5665 13.0262 51.5665 12.9131 51.9388C12.8297 52.2133 12.6247 52.3865 12.342 52.5692C12.2303 52.6419 12.121 52.7197 12.0726 52.8805L11.9655 53.1097C11.9053 53.2387 11.8583 53.2944 11.7506 53.3132C9.66275 53.7003 7.52497 53.7174 5.43265 53.3641C5.32486 53.3459 5.22806 53.2604 5.16705 53.1319L5.05866 52.9033C5.00933 52.7428 4.8996 52.6649 4.78753 52.5937C4.50441 52.4129 4.29844 52.2419 4.21288 51.9671C4.09761 51.5955 4.09761 51.5955 4.06094 51.1211L4.0188 33.872C3.94662 31.4641 4.45818 31.226 4.70364 30.3753Z"
            fill={isActive(size, 'large')}
          />

          <path
            d="M15.6929 36.3739C15.7572 36.1856 15.7991 36.0107 15.826 35.8768L15.8118 35.8768C15.783 35.8769 15.7594 35.8486 15.7593 35.8139L15.759 35.6921C15.7589 35.6574 15.7823 35.6289 15.8111 35.6288L15.9666 35.6283L22.2216 35.608L22.3603 35.6075C22.389 35.6074 22.4127 35.6358 22.4128 35.6705L22.4131 35.7929C22.4132 35.8276 22.3897 35.8561 22.361 35.8562L22.3463 35.8562C22.373 36.0297 22.4113 36.2485 22.4477 36.3525C22.6698 36.9886 23.1257 37.1644 23.0722 38.9707L23.1135 51.7225C23.0831 52.0785 23.0831 52.0785 22.9824 52.3578C22.9081 52.5638 22.7258 52.6937 22.4745 52.8309C22.3752 52.8855 22.278 52.9438 22.2349 53.0645L22.1396 53.2365C22.086 53.3332 22.0442 53.375 21.9485 53.3892C20.0923 53.6805 18.1919 53.6942 16.3321 53.4302C16.2363 53.4166 16.1503 53.3525 16.0961 53.2561L15.9998 53.0848C15.956 52.9644 15.8585 52.906 15.7589 52.8527C15.5073 52.7172 15.3243 52.5891 15.2483 52.383C15.146 52.1044 15.146 52.1044 15.1136 51.7485L15.0827 38.9967C15.0195 37.1908 15.4743 37.012 15.6929 36.3739Z"
            fill={isActive(size, 'medium')}
          />

          <path
            d="M25.5872 42.1262C25.6355 42.0007 25.667 41.8841 25.6872 41.7948L25.6766 41.7949C25.655 41.7949 25.6373 41.7761 25.6372 41.7529L25.637 41.6717C25.6369 41.6486 25.6545 41.6296 25.6761 41.6295L25.7927 41.6291L30.4839 41.6139L30.5879 41.6135C30.6095 41.6135 30.6272 41.6323 30.6273 41.6555L30.6275 41.7371C30.6276 41.7602 30.61 41.7792 30.5884 41.7793L30.5774 41.7793C30.5974 41.895 30.626 42.0409 30.6533 42.1102C30.8198 42.5341 31.1616 42.6513 31.1211 43.8555L31.1491 52.3567C31.1262 52.594 31.1262 52.594 31.0506 52.7803C30.9948 52.9176 30.8581 53.0043 30.6695 53.0958C30.5951 53.1322 30.5222 53.1711 30.4898 53.2516L30.4183 53.3663C30.378 53.4308 30.3467 53.4586 30.2749 53.4681C28.8827 53.6628 27.4574 53.6725 26.0626 53.497C25.9908 53.4879 25.9263 53.4452 25.8856 53.381L25.8135 53.2668C25.7807 53.1865 25.7075 53.1477 25.6329 53.1121C25.4442 53.0219 25.3069 52.9365 25.25 52.7991C25.1734 52.6134 25.1734 52.6134 25.1491 52.3762L25.129 43.875C25.082 42.671 25.4232 42.5517 25.5872 42.1262Z"
            fill={isActive(size, 'small')}
          />
        </g>
      )}
    </g>
  </svg>
);

Box.displayName = 'Box';
