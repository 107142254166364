/**
 * Click on target element
 *
 * @param target Target element to click
 */
const trackClick = (target: HTMLAnchorElement | null): void => {
  if (target && typeof target.onclick === 'function') {
    target.onclick.call(target, {
      currentTarget: target,
    } as unknown as MouseEvent);
  }
};

/**
 * Send add to cart tracking event to Connectif
 *
 * This function only works in the browser
 * @param productId ID of the product
 */
export const trackAddToCartClick = (productId: string): void => {
  if (typeof document === 'undefined') return;

  const target = document.getElementById(
    `${productId}_cart`,
  ) as HTMLAnchorElement | null;

  trackClick(target);
};

/**
 * Send go to product tracking event to Connectif
 *
 * This function only works in the browser
 * @param productId ID of the product
 */
export const trackGoToProductClick = (productId: string): void => {
  if (typeof document === 'undefined') return;

  const target = document.getElementById(
    `${productId}_go`,
  ) as HTMLAnchorElement | null;

  trackClick(target);
};
